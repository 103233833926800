<template>
  <div class="card" style="margin-top:0;">
    <div class="card-header card-header-icon card-header-rose">
      <div class="card-icon">
        <i class="material-icons">notes</i>
      </div>
      <h3 class="card-title">N° VIAJES Y BIOMASA (TON) POR CLIENTE Y ESPECIE</h3>
    </div>
    <div class="card-body">
      <v-row class="mx-0">
        <v-col cols="3">
          <v-select prepend-icon="mdi-navigation" v-model="periodo" :items="periodos" item-text="anio" item-value="id"
            label="Seleccione Año" color="blue darken-3" :reduce="item => item.id"></v-select>
        </v-col>
        <v-col cols="2">
          <v-select prepend-icon="mdi-navigation" v-model="faena" :items="faenas" item-text="nombre" item-value="id"
            label="Tipo de Faena" color="blue darken-3"></v-select>
        </v-col>
        <v-col class="text-right" cols="4">
          <v-btn class="mx-2" dark color="blue darken-1" @click="generar_reporte"><i class="material-icons">cached</i>
            Generar</v-btn>
          <v-btn class="mx-2" dark color="green" v-show="url" @click="descargar_excel"><i
              class="material-icons">text_snippet</i> Excel</v-btn>
          <!-- <v-btn class="mx-2" dark @click="downloadPDF" color="#C62828"> <i class="material-icons">picture_as_pdf</i> PDF</v-btn> -->
        </v-col>
      </v-row>

      <spinner v-if="spinner"></spinner>

      <v-simple-table v-if="data.length > 0">
        <thead v-html="cabeceras">

        </thead>
        <tbody class="bg-reporte-factura" v-html="filitas">

        </tbody>
      </v-simple-table>


    </div>


  </div>
</template>
<style scoped>
.table-wellboat {
  font-size: 16pt;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

.card {
  margin-top: 0;
}

.card .card-header-rose .card-icon {
  background: #263238;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(32, 30, 31, 0.822);
}
</style>
<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    spinner: false,
    meses: [],
    periodos: [],
    wellboats: [],
    faenas: [
      { id: 0, nombre: 'Todos' },
      { id: 1, nombre: 'Cosecha' },
      { id: 3, nombre: 'Smolt' },
      { id: 4, nombre: 'Mortalidad' },
      { id: 5, nombre: 'Baño' },
      { id: 6, nombre: 'Vacuna' },
    ],
    periodo: [],
    periodos_data: [],
    wellboat: null,
    faena: 0,
    data: [],
    url: null,
    filitas: '',
  }),
  async mounted() {
    await this.cargar_periodos()
  },
  methods: {
    async cargar_periodos() {
      let url = `${this.base_url}reportes/sw/cargar_periodos`;
      await this.axios
        .get(url, this.headers)
        .then((response) => {
          this.periodos = response.data.periodos;
          //this.periodo = this.periodos[0]
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async generar_reporte() {
      let url = `${this.base_url}reportes/sw/reporte_nro_viajes_biomasa_cliente_especie`;
      let body = {
        periodo: this.periodo,
        faena: this.faena
      }
      await this.axios
        .post(url, body, this.headers)
        .then((response) => {
          console.log('response', response.data)
          this.data = response.data.data
          this.meses = response.data.meses
          this.retornar_columnas()
          //this.periodos_data = response.data.periodos
          this.url = response.data.url
        })
        .catch((error) => {
          console.log(error);
        });
    },
    retornar_columnas() {
      this.filitas = ''
      this.data.forEach(item => {


        item.detalle_especies.forEach((det, idx) => {
          let clase = ""
          if(det.nombre == "Total"){
            clase = "subtotal"
          }

          if(item.razon_social == 'Total General'){
            clase = 'periodo'
          }
          
          let f = `<tr class="${clase}">`
          
          if (idx == 0) {
            f += `<td class="">${item.razon_social}</td>`
          }else{
            f += `<td></td>`
          }
          f += `<td>${det.nombre}</td>`
          /* if(mes.mes == "Enero"){
              fila+=`<td rowspan="12" class="periodo text-center">${periodo.periodo}</td>`
          } */
          det.meses.forEach(m => {
            f += `<td>${m.nro_viajes}</td><td>${this.$options.filters.formatear_miles(m.biomasa)}</td>`
          });
          f+=`</tr>`
          /* fila+=`<td>${mes.mes}</td>
          <td class="text-right">${this.$options.filters.formatear_miles(mes.biomasa_coho)}</td>
          <td class="text-right">${this.$options.filters.formatear_miles(mes.biomasa_salar)}</td>
          <td class="text-right">${this.$options.filters.formatear_miles(mes.biomasa_trucha)}</td></tr>` */
          this.filitas += f
        });
      });

    },
    descargar_excel() {
      window.open(this.url);
    }
  },
  computed: {
    ...mapState(["storage_url", "loading", "base_url", "headers", "user"]),
    cabeceras() {
      let string = `<tr><th rowspan="2" style="min-width:200px;">Cliente</th><th rowspan="2">Especie</th>`;
      this.meses.forEach(m => {
        string += `<th colspan="2" class="text-center">${m.mes}</th>`
      })
      string += `<th rowspan="2">Total N° Viajes</th><th rowspan="2">Total Biomasa</th></tr><tr>`
      this.meses.forEach(mes => {
        mes.mes
        string += `<th class="text-center">N° Viajes</th>
                      <th class="text-center">Biomasa (Ton)</th>`
      });
      return string;
    }
  },
  components: {},
};
</script>
<style>
th {
  background-color: #355f94;
  color: #fff !important;
  border: 1px solid #000301;
}

/* tbody.bg-reporte-factura tr:last-child{
          background: #ced4da;
          font-weight: 500;
      } */

.periodo {
  background: #ced4da;
  font-weight: 500;
}

.bg-yellow {
  background-color: #fff2b2;
  font-weight: 500;
}

.subtotal{
  background-color: #fefae0;
  font-weight: 500;
}
</style>
    